import React, { useEffect } from "react";
import { Link } from "gatsby";
import Layout from "../layouts/en";
import SEO from "../components/seo";
import $ from "jquery"


const FaqPage = ({ location }) => {

  useEffect(() => {
    expandData()
  }, []);

  const expandData = () => {
    $(".faq__title").on("click", function (e) {

      if (!$(this).hasClass("faq__title--active")) {
        var active = false;
      }
      $(".faq__title").removeClass("faq__title--active");
      if (active == false) {
        $(this).addClass("faq__title--active");
      }
    });
  }

  const specific_date = new Date('2022-01-09');
  const current_date = new Date();

  return (
    <Layout location={location}>
      <SEO title="Frequently Asked Questions " />

      <div id="contentWrap">
        <div id="index_content" className="admin-setting">
          <div className="faq">
            <h1 className="text--center">Frequently Asked Questions</h1>
            <div className="container container--800">
              <div className="faq__row">
                <label className="faq__title" htmlFor="faq_1">
                  What is AYANA Rewards?
                </label>
                <div className="faq__content">
                  <div className="faq__content__inner">
                    <p className="p--sm">
                      AYANA Rewards is a loyalty program designed for loyal customers who stay, dine and play in AYANA Villas Bali, AYANA Segara Bali, AYANA Resort Bali, RIMBA by AYANA Bali, AYANA Komodo Waecicu Beach, AYANA Lako Di’a, AYANA Midplaza JAKARTA and Delonix Hotel Karawang. Members receive exclusive benefits and rewards points that can be exchanged for memorable travel experiences such as free nights and upgrades in participating hotels.
                    </p>
                  </div>
                </div>
              </div>
              <div className="faq__row">
                <label className="faq__title" htmlFor="faq_12">
                  Who are the participating hotels?
                </label>
                <div className="faq__content">
                  <div className="faq__content__inner">
                    <p className="p--sm">
                      A: The participating brands are: The AYANA Villas Bali, AYANA Segara Bali, AYANA Resort Bali, RIMBA by AYANA Bali, AYANA Komodo Waecicu Beach, AYANA Lako Di’a, AYANA Midplaza JAKARTA and Delonix Hotel Karawang
                    </p>
                  </div>
                </div>
              </div>
              <div className="faq__row">
                <label className="faq__title" htmlFor="faq_2">
                  How do I sign up?
                </label>
                <div className="faq__content">
                  <div className="faq__content__inner">
                    <p className="p--sm">
                      A: You can sign up via our website at ayanarewards.com.
                    </p>
                  </div>
                </div>
              </div>
              <div className="faq__row">
                <label className="faq__title" htmlFor="faq_3">
                  Does it cost anything to join AYANA Rewards ?
                </label>
                <div className="faq__content">
                  <div className="faq__content__inner">
                    <p className="p--sm">
                      A: No, you can join the program for FREE.
                    </p>
                  </div>
                </div>
              </div>
              <div className="faq__row">
                <label className="faq__title" htmlFor="faq_4">
                  How can I earn points and how many points can I earn for each
                  booking?
                </label>
                <div className="faq__content">
                  <div className="faq__content__inner">
                    {current_date.getTime() > specific_date.getTime() ? (
                      <>
                        <p className="p--sm">
                           A: You will earn 1 point for every 10,000 IDR spent in our participating hotels, except at DELONIX Hotel Karawang, where members will earn 1 point for every IDR 15,000 spent on every eligible charge, before tax and service. Your points will be credited automatically to your account. AYANA Rewards GOLD members will earn an extra 10% bonus points and PLATINUM members will earn an extra 25% bonus points on eligible spend.
                          <br />
                          <span
                            style={{
                              backgroundColor: "rgb(255, 255, 255)",
                              fontSize: "15px",
                              fontVariantLigatures: "common-ligatures",
                            }}>
                            For more information about eligible charges in our participating hotels, please refer to our full{" "}
                            <strong>
                              <u>T&amp;C:</u>
                            </strong>
                            &nbsp;
                            <Link to="/terms-and-conditions-8">
                              <span style={{ color: "#000000" }}>
                                {`${location.origin}/terms-and-conditions-8`}
                              </span>
                            </Link>
                            <span style={{ color: "#000000" }}>.</span>
                          </span>
                        </p>
                      </>
                      ) : (
                      <>
                        <p className="p--sm">
                          A: You will earn 1 point for every 10,000 IDR spent in our
                          participating hotels. Your points will be credited
                          automatically to your account. AYANA Rewards GOLD members
                          will earn an extra 10% bonus points and PLATINUM members
                          will earn an extra 15% bonus points for every 10,000 IDR
                          spent in the hotel.
                          <br/>
                          <span
                            style={{
                              backgroundColor: "rgb(255, 255, 255)",
                              fontSize: "15px",
                              fontVariantLigatures: "common-ligatures",
                            }}>
                            For more information about eligible charges in our
                            participating hotels, please refer to our full{" "}
                            <strong>
                              <u>T&amp;C:</u>
                            </strong>
                            &nbsp;
                            <Link to="/terms-and-conditions-8">
                              <span style={{ color: "#000000" }}>
                                {`${location.origin}/terms-and-conditions-8`}
                              </span>
                            </Link>
                            <span style={{ color: "#000000" }}>.</span>
                          </span>
                        </p>
                      </>
                    )}
                  </div>
                </div>
              </div>
              <div className="faq__row">
                <label className="faq__title" htmlFor="faq_5">
                  How many rooms can I earn points per stay at participating
                  properties?
                </label>
                <div className="faq__content">
                  <div className="faq__content__inner">
                    <p className="p--sm">
                      {current_date.getTime() > specific_date.getTime() ? (
                          <>
                          A: You can earn points for up to five guest rooms with the same stay dates at a participating property when (1) each room is paid by you and all charges are settled at check-out (2) one room is occupied by you.
                          </>
                          ) : (
                          <>
                          A: You can earn points for up to five guest rooms with the
                          same stay dates at a participating property when (1) each
                          room is paid you and all charges are settled at check out
                          (2) one room is occupied by you.
                          </>
                      )}
                      
                    </p>
                  </div>
                </div>
              </div>
              <div className="faq__row">
                <label className="faq__title" htmlFor="faq_6">
                  Do I get points for rooms I paid for, even if I&rsquo;m not
                  staying at the hotel?
                </label>
                <div className="faq__content">
                  <div className="faq__content__inner">
                    <p className="p--sm">
                      A: No. The program require that you personally stay at the
                      hotel to be eligible for points.
                    </p>
                  </div>
                </div>
              </div>
              <div className="faq__row">
                <label className="faq__title" htmlFor="faq_7">
                  How and when can I redeem my points?
                </label>
                <div className="faq__content">
                  <div className="faq__content__inner">
                    <p className="p--sm">
                      {current_date.getTime() > specific_date.getTime() ? (
                          <>
                          A: You can log in to your account via ayanarewards.com and visit <b>Redeem</b> page to see the rewards catalog and redeem impressive lifestyle and travel experiences for your favorite reward. 
                          </>
                          ) : (
                          <>
                          A: You can log in to your account via ayanarewards.com to
                          see the rewards catalog and redeem impressive lifestyle
                          and travel experiences for your favorite reward.
                          </>
                      )}
                    </p>
                  </div>
                </div>
              </div>
              <div className="faq__row">
                <label className="faq__title" htmlFor="faq_8">
                  Why can&rsquo;t I redeem points?
                </label>
                <div className="faq__content">
                  <div className="faq__content__inner">
                    <p className="p--sm">
                      A: There are some cases where you cannot redeem your
                      points: a. You don&rsquo;t have enough points to redeem
                      for the reward you have chosen. In this case, please
                      choose another reward with less required points or earn
                      more to redeem later. b. Your points are expired. c. The
                      reward you have chosen is not available at the moment.
                    </p>
                  </div>
                </div>
              </div>
              <div className="faq__row">
                <label className="faq__title" htmlFor="faq_9">
                  How long does it take for reward points to be credited to my
                  account?
                </label>
                <div className="faq__content">
                  <div className="faq__content__inner">
                    <p className="p--sm">
                      A: Points will be credited to member&#39;s account up to 7
                      working days after check out.
                    </p>
                  </div>
                </div>
              </div>
              <div className="faq__row">
                <label className="faq__title" htmlFor="faq_10">
                  How long are my AYANA Rewards points valid for?
                </label>
                <div className="faq__content">
                  <div className="faq__content__inner">
                    <p className="p--sm">
                      A: Points will be valid for 10 years after the issued date.
                    </p>
                  </div>
                </div>
              </div>
              <div className="faq__row">
                <label className="faq__title" htmlFor="faq_11">
                  How do I check my point balance, transactions and redemption
                  history?
                </label>
                <div className="faq__content">
                  <div className="faq__content__inner">
                    <p className="p--sm">
                      {current_date.getTime() > specific_date.getTime() ? (
                          <>
                          A: You can find all your point details on your <b>Profile</b> menu after logging in to ayanarewards.com. 
                          </>
                          ) : (
                          <>
                          A: You can find all your point details when you log in to
                          ayanarewards.com.
                          </>
                      )}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default FaqPage;
